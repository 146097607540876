// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("/var/www/gatsby/feladatkiadas/delegoo-website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-404-js": () => import("/var/www/gatsby/feladatkiadas/delegoo-website/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adatkezeles-js": () => import("/var/www/gatsby/feladatkiadas/delegoo-website/src/pages/adatkezeles.js" /* webpackChunkName: "component---src-pages-adatkezeles-js" */),
  "component---src-pages-aszf-js": () => import("/var/www/gatsby/feladatkiadas/delegoo-website/src/pages/aszf.js" /* webpackChunkName: "component---src-pages-aszf-js" */),
  "component---src-pages-bemutato-js": () => import("/var/www/gatsby/feladatkiadas/delegoo-website/src/pages/bemutato.js" /* webpackChunkName: "component---src-pages-bemutato-js" */),
  "component---src-pages-page-2-js": () => import("/var/www/gatsby/feladatkiadas/delegoo-website/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

